















































































































































































































































































import moment from "moment";
import Component from "vue-class-component";
import { PaymentResource, http } from "@/resources";
import List from "@/components/List";
import {
  Payment,
  User,
  Scene,
  PaymentGateway,
  flowGateways
} from "@/resources/interfaces";

@Component
export default class PaymentList extends List<Payment> {
  name = "payment";
  resource = PaymentResource;
  totalAmount: {
    amount: number;
    assets: number;
    debt: number;
    revenue: number;
    balance: number;
    times: number;
    points: number;
  } = {
    amount: NaN,
    assets: NaN,
    debt: NaN,
    revenue: NaN,
    balance: NaN,
    times: NaN,
    points: NaN
  };
  availableScenes: Scene[] = [];
  amendPayment: Payment | null = null;
  loading = false;

  get showDateRange() {
    return (
      this.$user.can("PAYMENT_LAST_WEEK") ||
      this.$user.can("PAYMENT_LAST_MONTH") ||
      this.$user.can("PAYMENT_ALL_DATE") ||
      this.$user.can("BOSSBOARD")
    );
  }

  async queryData() {
    const queriedData = await (List as any).options.methods.queryData.call(
      this
    );
    if (!queriedData) return;
    const [amount, assets, debt, revenue, balance, times, points] = (
      queriedData.$headers["total-amount"] || "-,-,-,-,-,-,-"
    ).split(",") as number[];
    this.totalAmount.amount = amount;
    this.totalAmount.assets = assets;
    this.totalAmount.debt = debt;
    this.totalAmount.revenue = revenue;
    this.totalAmount.balance = balance;
    this.totalAmount.times = times;
    this.totalAmount.points = points || 0;
    return queriedData;
  }
  get validAmend() {
    if (!this.amendPayment?.createdAt) return false;
    return (
      Math.abs(
        this.amendPayment.assets -
          this.amendPayment.revenue -
          this.amendPayment.debt
      ) < 1e-10
    );
  }
  showCreate() {
    // this.$router.push("/payment/add");
  }
  goToCustomer(customer: User) {
    this.$router.push(`/user/${customer.id}`);
  }
  goToRelatedItem(item: Payment) {
    if (item.booking) {
      return this.$router.push(`/booking/${item.scene}/${item.booking}`);
    } else if (item.stockLog) {
      return this.$router.push(`/stock-log/${item.stockLog}`);
    } else if (item.customer) {
      return this.$router.push(`/user/${item.customer.id}`);
    }
  }
  disabledDates(date: Date) {
    if (this.$user.can("PAYMENT_ALL_DATE")) {
      return false;
    } else if (this.$user.can("PAYMENT_LAST_MONTH")) {
      const start = moment().subtract(1, "month").startOf("month").valueOf();
      return date.valueOf() < start;
    } else if (this.$user.can("PAYMENT_LAST_WEEK")) {
      const start = moment().subtract(1, "week").startOf("week").valueOf();
      return date.valueOf() < start;
    } else {
      const start = moment().startOf("day").valueOf();
      return date.valueOf() < start;
    }
  }
  async download() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    this.loading = true;
    try {
      const { downloadUrl } = (
        await http.get("/payment-sheet", { params })
      ).data;
      window.open(downloadUrl);
    } catch (e) {
      //
    }
    this.loading = false;
  }
  async downloadGatewayAssets() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    this.loading = true;
    try {
      const { downloadUrl } = (
        await http.get("/payment-daily-gateway-assets-sheet", { params })
      ).data;
      window.open(downloadUrl);
    } catch (e) {
      //
    }
    this.loading = false;
  }
  amendable(payment: Payment) {
    if (
      ![...flowGateways, PaymentGateway.Ar, PaymentGateway.Agency].includes(
        payment.gateway
      )
    ) {
      return false;
    }
    return (
      this.$user.can("BOOKING_ALL_STORE", "CASHIER", "PAYMENT") ||
      (this.$user.can("CASHIER", "PAYMENT") &&
        this.$user.store?.id === payment.store &&
        payment.gateway === PaymentGateway.Ar)
    );
  }
  showAmendDialog(payment: Payment) {
    if (!this.amendable(payment)) {
      return;
    }
    this.amendPayment = { ...payment, createdAt: new Date(payment.createdAt) };
  }
  async amend() {
    if (!this.amendPayment) return;
    const payment = await PaymentResource.save(this.amendPayment);
    const index = this.queriedData.findIndex(i => i.id === payment.id);
    this.queriedData.splice(index, 1, payment);
    this.amendPayment = null;
    this.$notify({
      message: "更新成功",
      icon: "check",
      type: "success"
    });
  }
  created() {
    this.searchQuery = {
      date: moment().format("YYYY-MM-DD"),
      scene: [],
      paid: true,
      dateType: "created",
      gateway: []
    };
    if (this.$route.query.date) {
      this.searchQuery.date = this.$route.query.date;
    }
    if (this.$route.query.dateEnd) {
      this.searchQuery.dateEnd = this.$route.query.dateEnd;
    }
    if (this.$route.query.customer) {
      this.searchQuery.customer = this.$route.query.customer;
      !this.$route.query.date && (this.searchQuery.date = undefined);
    }
    if (this.$route.query.card) {
      this.searchQuery.card = this.$route.query.card;
      !this.$route.query.date && (this.searchQuery.date = undefined);
    }
    if (this.$route.query.coupon) {
      this.searchQuery.coupon = this.$route.query.coupon;
      !this.$route.query.date && (this.searchQuery.date = undefined);
    }
    if (this.$user.store) {
      this.searchQuery.store = this.$user.store.id;
    }
    if (this.$user.can("FOOD_BOOKING")) {
      this.availableScenes.push(Scene.FOOD);
    }
    if (this.$user.can("PLAY_BOOKING")) {
      this.availableScenes.push(Scene.PLAY);
      this.availableScenes.push(Scene.CARD);
      this.availableScenes.push(Scene.BALANCE);
      this.availableScenes.push(Scene.PERIOD);
    }
    if (this.$user.can("EVENT_BOOKING")) {
      this.availableScenes.push(Scene.EVENT);
    }
    if (this.$user.can("CARE_BOOKING")) {
      this.availableScenes.push(Scene.CARE);
    }
    if (this.$user.can("GIFT_BOOKING")) {
      this.availableScenes.push(Scene.GIFT);
      this.availableScenes.push(Scene.RETAIL);
    }
    if (this.$user.can("PARTY_BOOKING")) {
      this.availableScenes.push(Scene.PARTY);
    }
    if (this.$user.can("CASHIER")) {
      this.availableScenes = Object.keys(this.$sceneNames) as Scene[];
    }
  }
}
