



































































import { Component } from "vue-property-decorator";
import List from "@/components/List";
import { http, UserResource } from "@/resources";
import { User } from "@/resources/interfaces";

@Component
export default class UserList extends List<User> {
  name = "user";
  resource = UserResource;
  stats: {
    totalBalance?: number;
    totalBalanceDeposit?: number;
    totalValidCardBalance?: number;
    totalValidCardBalanceDeposit?: number;
  } = {};
  async queryData() {
    const queriedData = await (List as any).options.methods.queryData.call(
      this
    );
    if (!queriedData) return;
    return queriedData;
  }
  async created() {
    this.searchQuery = { role: "customer" };
    this.stats = (await http.get("stats/user-balance")).data;
  }
}
